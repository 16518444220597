* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #e2e7ea;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.reg .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.fuelReg .MuiInputBase-sizeSmall {
  padding: 5.5px 14px !important;
}
.custom-grid, .custom-grid2 {
  width: 100%;
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.custom-grid2 {
  margin: 6px 0;
  font-size: 12px;
  font-weight: bold;
}
.custom-grid label, .custom-grid2 label {
  width: 40%;
  text-align: right;
  padding-right: 15px;
}
.custom-grid2 label{
  width: 30%;
}
.custom-grid > div {
  width: 60%;
}

.custom-grid2 > div {
  width: 70%;
}

/* .custom-grid2 div, .custom-grid2 input {
  padding: 2px 0px;
} */

.customGrid3 div, .customGrid3 input {
  padding: 2px 5px;
}

.custom-grid2 input {
  padding: 4px 5px;
  font-size: 12px;
}

.customSelect > div {
  padding: 2px 4px;
  font-size: 12px;
}

/* .custom-grid2 > div {
  padding: 0;
} */
.custom-grid3 {
  width: 100%;
  margin: 8px 0;
  display: block;  
  align-items: center;
}

.custom-grid3 label {
  display: block;
  padding: 5px 0;
}

.custom-grid3 > div {
  width: 100%;
}

.customTable {
  width: 100%;
}

.customTable td {
  padding: 3px;
  vertical-align: bottom;
  font-size: 12px;
}

.customTable div.customSelect > div {
  padding: 2px 5px;
}

.customTable input {
  padding: 0;
  font-size: 12px;
  padding: 4px 5px;
}

.customFilter input, .customFilter div, .customFilter button {
  padding: 0;
  font-size: 12px;
}

.customFilter input, .customFilter div {
  padding: 10px 8px;
}

.customFilter button {
  padding: 8px 12px;
}

.custom-grid5 {
  margin-top: 5px;
}

.custom-grid5 label span {
  font-size: 12px;
}

.custom-grid5 input, .custom-grid5 div {
  padding: 5px 4px;
  font-size: 12px;
}

.customFooter label span{
  font-size: 12px;
}

.page_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.page_head .pageHead, .page_head .page_actions {
  width: 50%;
}

.removePadd input {
  padding: 2px;
}