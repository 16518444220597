body {
  padding-top: 77px;
}
.main {
  width: 100%;
  float: left;
  margin: auto;
  padding: 10px 20px;
  min-height: calc(100vh - 150px);
}
.main1 {
  width: 100%;
  float: left;
  margin: auto;
  padding: 0px;
  min-height: calc(100vh - 130px);  
}


.show-menu .left-panel nav::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.show-menu .left-panel::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.show-menu .left-panel nav::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.show-menu .left-panel nav:horizontal {
  height: 5px;
}

body {
  scrollbar-width: thin;
  /* scrollbar-color: #90A4AE #CFD8DC; */
}

.show-menu .left-panel nav::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 6px;
  height: 5px;
}

.show-menu .left-panel nav::-webkit-scrollbar-thumb {
  /* background: rgba(0, 0, 0, 0.4); */
  border-radius: 6px;
  border: 1px solid #eaeaea;
  min-height: 40px;
  max-height: 80px;
}

.show-menu .left-panel nav::-webkit-scrollbar-thumb:hover {
  /* background: rgba(0, 0, 0, 0.4); */
}

.left-panel {
  min-height: calc(100vh - 90px);
  width: 250px;
  background-color: #3f3f3f;
  color: #f1f7ff;
  float: left;
  position: fixed;
  left: 0;
  top: 90px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.right-panel {
  min-height: calc(100vh - 90px);
  margin-left: 250px;
}