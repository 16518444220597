.header {
  width: 100%;
  padding: 0 20px;
  background-color: #337ab7;
  color: #fff;
  clear: both;
  height: 70px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header:after {
  content: "";
  clear: both;
  display: table;
}

.header button {
  color: #fff;
}

.head {
  padding: 5px 0;  
}

.head:after {
  content: "";
  clear: both;
  display: table;
}

.logo {
  font-size: 1.8rem;
  float: left;
  color: #b7c0cd;
}

.logo a {
  color: #fff;
  text-decoration: none;
}
.logo a:hover {
  color: #fff;
}

.logout {
  text-align: right;
  float: right;
}

.burger {
  margin-top: 5px;
  margin-right: 15px;
  float: left;
  width: 25px;
  height: 24px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
}

.burger .bar {
  width: 25px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  border: 1px solid #fff;
}

.burger .bar:nth-of-type(2) {
  top: calc(50% - 9px);
}
.burger .bar:nth-of-type(3) {
  top: calc(50% + 9px);
  margin-top: -1.8px;
}

.burger .bar:nth-of-type(1) {
  margin-top: -1.2px;
}

.burger.active .bar:nth-of-type(1) {
  display: none;
}
.burger.active .bar:nth-of-type(2) {
  top: 50%;
  transform: translate(-50%, 0%) rotate(45deg);
}
.burger.active .bar:nth-of-type(3) {
  top: 50%;
  transform: translate(-50%, 0%) rotate(-45deg);
  margin-top: 0;
}

.logoWrapper {
  display: inline-flex;
  align-items: center;
}
