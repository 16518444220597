@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #e2e7ea;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.left-panel {
  height: calc(100vh - 90px);
  width: 0;
  /* background-color: #052830; */
  background-color: #3f3f3f;
  color: #f1f7ff;
  float: left;
  position: fixed;
  left: 0;
  top: 90px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  overflow: auto;
  z-index: 100;
}

.nav-icons {
  margin-right: 8px;
}

.nav-icons svg {
  font-size: 16px;
  position: relative;
  top: 2px;
}

.right-panel {
  min-height: calc(100vh - 140px);
  margin-left: 0;
  transition: all 0.3s ease-in-out;
}

.show-menu .left-panel {
  width: 230px;
}

.show-menu .right-panel {
  margin-left: 230px;
}
