.nav {
  padding: 0px 0;
  height: 40px;
  width: 100%;
  background-color: #636e72;
  /* overflow: auto; */
}
.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: -12px;
}

.nav li {
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.nav ul li a {
  color: #b7c0cd;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 20px;
}

.nav ul li li a {
  background: url("../../images/arrow.svg") no-repeat 8px 15px;
  background-size: 8px;
  padding-left: 25px;
}

.nav > ul > li > a {
  font-size: 1.1em;
}

.nav li ul {
  max-height: 0px;
  margin-left: 10px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  /* display: none; */
  width: 0px;
}

.nav > ul > li::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 15px;
  font-family: "Helvetica Neue";
  background: url("../../images/arrow.svg") no-repeat left top;
  width: 12px;
  height: 12px;
}

.nav > ul > li.active::after {
  background-image: url("../../images/arrow-down.svg");
}

.nav > ul > li.active::before {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 25px);
  /* background-color: #337ab7; */
  left: 0;
  top: 15px;
}

.nav > ul > li:hover > ul {
  display: block;
  background-color: #636e72;
  width: 220px;
  max-height: 500px;
  margin-top: -5px;
  position: absolute;
  z-index: 5;
  opacity: 1;
}

.nav > ul > li:hover > a {
  color: #fff;
}

.nav > ul > li > ul > li:hover a {
  color: #fff;
}

.nav > ul > li > ul > li {
  display: block;
}
